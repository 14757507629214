<template>
  <div v-if="data.properties.filed_content != 'Hide'">
    <el-row>
      <el-col
        :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24"
      >
        <span>
          <label
            for="text"
            v-if="
              hasLabel &&
              !fromRepeatable &&
              data.properties.filed_content != 'Hide'
            "
            :style="getLabelStyles"
            >{{ data.label }}</label
          >
         <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
            <i class="el-icon-info" slot="reference"></i
          >
        </el-popover>
        </span>
        
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div v-if="!isView && data.properties.filed_content != 'Hide'">
          <el-checkbox-group
            v-model="form[data.key]"
            :disabled="checkReadonly() || readonly()"
            :change="changeField"
            @change="applyFormRules"
            :max="getMaxValue"
            class="custom-checkbox"
          >
            <ol :class="data.view_type === true ? '' : 'horizontalView'">
              <li
                v-for="(option, index) of options"
                :key="index"
                :class="
                  data.view_type === true
                    ? 'checkbox-option'
                    : 'horizontalView checkbox-option'
                "
              >
                <el-checkbox :value="option" :label="option" border>
                  {{ option }}</el-checkbox
                >
              </li>
            </ol>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>

    <p
      v-if="missingKeys.includes('' + data.key)"
      style="color: red; font-size: 14px"
    >
      {{ data.label }} is Required
    </p>

    <p v-if="isView && data.properties.filed_content != 'Hide'">
      <template v-if="form && form[data.key] && form[data.key].length">
        {{ form[data.key].join(",") }}
      </template>
      <template v-else>-</template>
    </p>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { bus } from "../../../main";

export default {
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "country",
    "form",
    "fromRepeatable",
    "isView",
    "hasLabel",
    "selectedEntityDataId",
    "isFromDocument",
    "checkIsDisabled",
    "colorFields",
    "fromEntityViews"
  ],
  data() {
    return {
      validations: [],
      options: [],
      missingKeys: [],
      showLabel: true,
      isDefalutPos: true,
      localValue: [],
    };
  },
  computed: {
    getMaxValue() {
      if (this.form && this.form[this.data.key + "/max_value"]) {
        return this.form[this.data.key + "/max_value"];
      }
      return this.data.max_selection || undefined;
    },
    getStyle() {
      return this.hasLabel
        ? `height:${this.data.height - 5}px;width:${this.data.width - 5}px;`
        : "";
    },
    // getStyle() {
    //   let style = this.hasLabel ? `height:${this.data.height - 30}px;` : "";
    //   style =
    //     style +
    //     (this.missingKeys.includes("" + this.data.key)
    //       ? `border: 1px solid red;border-radius: 5px; font-size: 14px;`
    //       : "");
    //   return style;
    // },
  },

  mounted() {
    this.options = this.data.options;
    if (
      !this.form[this.data.key] ||
      typeof this.form[this.data.key] !== "object" ||
      !this.form[this.data.key].length 
    ) {
      this.$set(this.form, this.data.key, []);
    }
    bus.$on("missingRequiredFields", (data) => {
      this.missingKeys = data;
    });
  },
  methods: {
    changeField() {
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    valueChange() {
      // bus.$emit("updatedActionFormData", this.form);
      // bus.$emit("updatedFormData", this.form);
      // if (this.form[this.data.key]) {
      //   let formIndex = this.missingKeys.findIndex((e) => e === this.data.key);
      //   this.missingKeys.splice(formIndex, 1);
      // }
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
    },
  },
};
</script>

<style lang="scss">
.checkbox-option {
  margin-bottom: 10px; /* Adjust the space between options at the bottom */
}
// .el-checkbox-group {
//   font-size: 0;
//   display: flex;
//   flex-wrap: wrap;
// }
// .check {
//   margin-top: -2px !important;
// }
.GroupBox {
  height: 74px !important;
}
.horizontalView {
  display: inline-block;
  margin-right: 10px;
  padding: 0px;
}

.custom-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: #333333 !important;
}

</style>
