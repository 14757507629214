var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 8 : _vm.data.description ? 20 : 24}},[_c('span',[(
            _vm.hasLabel &&
            !_vm.fromRepeatable &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]):_vm._e(),(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[(!_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('div',[_c('el-checkbox-group',{staticClass:"custom-checkbox",attrs:{"disabled":_vm.checkReadonly() || _vm.readonly(),"change":_vm.changeField,"max":_vm.getMaxValue},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('ol',{class:_vm.data.view_type === true ? '' : 'horizontalView'},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:_vm.data.view_type === true
                  ? 'checkbox-option'
                  : 'horizontalView checkbox-option'},[_c('el-checkbox',{attrs:{"value":option,"label":option,"border":""}},[_vm._v(" "+_vm._s(option))])],1)}),0)])],1):_vm._e()])],1),(_vm.missingKeys.includes('' + _vm.data.key))?_c('p',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.data.label)+" is Required ")]):_vm._e(),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',[(_vm.form && _vm.form[_vm.data.key] && _vm.form[_vm.data.key].length)?[_vm._v(" "+_vm._s(_vm.form[_vm.data.key].join(","))+" ")]:[_vm._v("-")]],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }